import Axios, { AxiosResponse } from 'axios';
import { User } from './Users';
import { UpdateUserPasswordRequest, UpdateUserRequest } from '../Settings/Interfaces/SettingsRequests';

const getUser = async (): Promise<User> => {
  const request = await Axios({
    method: 'get',
    url: '/api/auth/user',
  });
  return request.data.data;
};

const signIn = async (email: string, password: string, remember: boolean): Promise<User> => {
  // Login...
  await Axios({
    data: {
      email,
      password,
      remember,
    },
    method: 'post',
    url: '/login',
  });
  return getUser();
};

const signOut = async () => {
  await Axios({
    method: 'post',
    url: '/logout',
  });
};

const updateUser = async (data: UpdateUserRequest): Promise<AxiosResponse<void>> => {
  const request = await Axios({
    data,
    method: 'put',
    url: '/api/auth/user',
  });

  return request;
};

const changePassword = async (data: UpdateUserPasswordRequest): Promise<AxiosResponse<void>> => {
  const request = await Axios({
    data,
    method: 'put',
    url: '/api/auth/user-password',
  });

  return request;
};

export {
  signIn,
  getUser,
  updateUser,
  signOut,
  changePassword,
};
