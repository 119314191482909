import * as React from 'react';
import { NavLink } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import BSNav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faQuestionCircle, faUserCog } from '@fortawesome/free-solid-svg-icons';
import Tooltip from 'react-bootstrap/Tooltip';
import { useAuth } from '../../Context/AuthContext';
import { useTenant } from '../../Tenants/TenantContext';
import { useLocation } from '../../Locations/LocationContext';
import HooverTooltip from '../Core/HoverTooltip';
import { SUPPORT_LINK } from '../../Helpers/config';

const Nav = () => {
  const { t, i18n } = useTranslation('nav');
  const { user, signOut } = useAuth();
  const { tenant } = useTenant();
  const { location } = useLocation();

  return (
    <Navbar
      collapseOnSelect
      expand="md"
      bg="dark"
      variant="dark"
      fixed="top"
      sticky="top"
    >
      <Container fluid="xl">
        <Navbar.Toggle aria-controls="navbar" />
        <Navbar.Collapse id="navbar">
          <BSNav className="mr-auto align-items-center">
            <HooverTooltip text={t('tooltips.go_to_tenants')}>
              <BSNav.Link
                as={NavLink}
                to={`/${i18n.language}`}
              >
                <FontAwesomeIcon icon={faHome} />
              </BSNav.Link>
            </HooverTooltip>
            {tenant && location && (
              <>
                <span className="text-white">|</span>
                <HooverTooltip text={t('tooltips.go_to_dashboard')}>
                  <BSNav.Link
                    as={NavLink}
                    to={`/${i18n.language}/${tenant.url_slug}/location/${location.uuid}`}
                    className="menu-link"
                  >
                    {`${location.customer.name} - ${location.name}`}
                  </BSNav.Link>
                </HooverTooltip>
              </>
            )}
          </BSNav>
          <BSNav>
            <>
              {user && location && (
              <>
                <BSNav.Link
                  as={NavLink}
                  to={`/${i18n.language}/${tenant.url_slug}/location/${location.uuid}/preferences`}
                >
                  {t('preferences')}
                </BSNav.Link>
                <NavDropdown id="orders-nav" title={t('order_plural')}>
                  <NavDropdown.Item
                    as={NavLink}
                    to={`/${i18n.language}/${tenant.url_slug}/location/${location.uuid}/orders/history`}
                  >
                    {t('order_history')}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={NavLink}
                    to={`/${i18n.language}/${tenant.url_slug}/location/${location.uuid}/standing-orders`}
                    exact
                  >
                    {t('standing_orders')}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={NavLink}
                    to={`/${i18n.language}/${tenant.url_slug}/location/${location.uuid}/orders/create`}
                    exact
                  >
                    {t('new_order')}
                  </NavDropdown.Item>
                </NavDropdown>
              </>
              )}
              <a
                className="nav-link"
                target="_blank"
                rel="noopener noreferrer"
                href={SUPPORT_LINK}
              >
                <OverlayTrigger
                  placement="bottom"
                  overlay={(
                    <Tooltip id="support-tooltip">
                      {t('tooltips.contact_support')}
                    </Tooltip>
                  )}
                >
                  <FontAwesomeIcon
                    icon={faQuestionCircle}
                    size="lg"
                    className="help-icon"
                  />
                </OverlayTrigger>
              </a>
              <NavDropdown
                id="user"
                title={<FontAwesomeIcon icon={faUserCog} />}
                className="justify-content-end user-profile--dropdown"
              >
                {user && user.tenant_users.length > 1 && (
                <>
                  <NavDropdown.Header>{t('select_tenant')}</NavDropdown.Header>
                  {user.tenant_users.map((option) => (
                    <NavDropdown.Item
                      active={tenant?.url_slug === option.tenant.url_slug}
                      as={NavLink}
                      key={option.tenant.url_slug}
                      to={`/${i18n.language}/${option.tenant.url_slug}/home`}
                    >
                      {option.tenant.name}
                    </NavDropdown.Item>
                  ))}
                  <NavDropdown.Divider />
                </>
                )}
                {user && (
                <NavDropdown.Item
                  as={NavLink}
                  to={`/${i18n.language}/user`}
                >
                  {t('profile')}
                </NavDropdown.Item>
                )}
                <NavDropdown.Item
                  active={i18n.language === 'en'}
                  onClick={() => i18n.changeLanguage('en')}
                >
                  {t('shared:languages.short.en')}
                </NavDropdown.Item>
                <NavDropdown.Item
                  active={i18n.language === 'fr'}
                  onClick={() => i18n.changeLanguage('fr')}
                >
                  {t('shared:languages.short.fr')}
                </NavDropdown.Item>
                {user && (
                <NavDropdown.Item
                  onClick={signOut}
                >
                  {t('sign_out')}
                </NavDropdown.Item>
                )}
              </NavDropdown>
            </>
          </BSNav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Nav;
