import React, { ReactNode, ReactNodeArray } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from './AuthContext';
import { UserProvider } from './UserContext';
import { MaintenanceModeProvider } from './MaintenanceModeContext';

interface AppProvidersProps {
    children: ReactNode|ReactNodeArray;
}

function AppProviders({ children }: AppProvidersProps) {
  return (
    <Router>
      <HelmetProvider>
        <MaintenanceModeProvider>
          <AuthProvider>
            <UserProvider>
              {children}
            </UserProvider>
          </AuthProvider>
        </MaintenanceModeProvider>
      </HelmetProvider>
    </Router>
  );
}

export default AppProviders;
