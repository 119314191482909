import * as React from 'react';
import { useUser } from './Context/UserContext';
import FullPageSpinner from './Components/Core/FullPageSpinner';
import { useMaintenanceMode } from './Context/MaintenanceModeContext';
import MaintenancePage from './Components/Page/MaintenancePage';

const loadAuthenticatedApp = () => import('./AuthenticatedApp');
const AuthenticatedApp = React.lazy(loadAuthenticatedApp);
const UnauthenticatedApp = React.lazy(() => import('./UnauthenticatedApp'));

const App = () => {
  const user = useUser();
  const { inMaintenanceMode } = useMaintenanceMode();
  // // pre-load the authenticated side in the background while the user's
  // // filling out the login form.
  React.useEffect(() => {
    loadAuthenticatedApp();
  });

  if (inMaintenanceMode) {
    return <MaintenancePage />;
  }

  return (
    <React.Suspense fallback={<FullPageSpinner />}>
      {user ? <AuthenticatedApp /> : <UnauthenticatedApp />}
    </React.Suspense>
  );
};

export default App;
