import React, { ReactNode, ReactNodeArray } from 'react';
import { useAuth } from './AuthContext';
import { User } from '../Data/Users';

const UserContext = React.createContext<User|null>(null);

interface UserProviderProps {
    children: ReactNode|ReactNodeArray;
}

function UserProvider(props: UserProviderProps) {
  const user = useAuth();
  return <UserContext.Provider value={user.user} {...props} />;
}

function useUser() {
  const context = React.useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
}

export { UserProvider, useUser };
