import { ResultSet } from '../../Helpers/api/api';
import gatewayRequest, { AVAILABLE_HTTP_METHODS } from '../../Helpers/api/gatewayRequest';
import {
  SettingKey,
  Setting,
} from '../Interfaces/types';

export default class SettingsService {
  public static getSetting<SettingValue>(
    tenant_id: number,
    key: SettingKey,
  ) {
    return gatewayRequest<ResultSet<Setting<SettingValue>>>(
      `${tenant_id}/settings/${key}`,
      AVAILABLE_HTTP_METHODS.GET,
    );
  }
}
