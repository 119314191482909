import { MediaTypes } from './resources/media/types';
import { NotificationPreferenceOptions } from '../Locations/Interfaces/requests';

export const SUPPORT_LINK = 'https://support.routeique.com/kb-tickets/new';

export const ACCEPTED_MIME_TYPES: MediaTypes[] = [
  'image/jpeg',
  'image/png',
  'text/plain',
  'text/csv',
  'application/pdf',
  'application/zip',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msword',
];

export const CUSTOMER_LOCATION_NOTIFICATION_PREFERENCES: NotificationPreferenceOptions = {
  order_created: [
    'email',
    'sms',
  ],
  order_delivered: [
    'email',
    'sms',
  ],
  order_reminder: [
    'email',
    'sms',
  ],
  order_rescheduled: [
    'email',
    'sms',
  ],
};
