import React, {
  ReactNode,
  ReactNodeArray, useEffect, useState,
} from 'react';
import {
  useParams,
} from 'react-router-dom';
import { useAuth } from '../Context/AuthContext';
import { useTenant } from '../Tenants/TenantContext';
import { Location } from './Interfaces/types';
import FullPageSpinner from '../Components/Core/FullPageSpinner';
import LocationServices from './Services/LocationServices';

interface LocationContextValue {
  location: Location;
}

const LocationContext = React.createContext<LocationContextValue>({} as LocationContextValue);

interface LocationProviderProps {
  children: ReactNode|ReactNodeArray;
}

interface LocationRouteProps {
  locationUuid: string;
}

function LocationProvider(props: LocationProviderProps) {
  const { locationUuid } = useParams<LocationRouteProps>();
  const [location, setLocation] = useState<null|Location>(null);
  const { tenant } = useTenant();
  // eslint-disable-next-line no-unused-vars
  const { signOut } = useAuth();

  useEffect(() => {
    LocationServices.show(tenant.id, locationUuid)
      .then(({ data: { data } }) => setLocation(data))
      .catch(() => {
        // kicking user out of order portal
        signOut();
      });
  }, [tenant.id, locationUuid]);

  if (location === null) {
    return <FullPageSpinner />;
  }

  return (
    <LocationContext.Provider
      value={{
        location,
      }}
      {...props}
    />
  );
}

function useLocation() {
  const context = React.useContext(LocationContext);
  if (context === undefined) {
    throw new Error('useLocation must be used within a LocationProvider');
  }
  return context;
}

export { LocationProvider, useLocation };
