/**
 * For more information on how to use this function, visit the following page:
 * https://routeique.atlassian.net/wiki/spaces/RP/pages/968523899
 */
import axios, {
  AxiosPromise, AxiosRequestConfig, CancelToken, Method,
} from 'axios';
import qs from 'qs';

export enum AVAILABLE_HTTP_METHODS {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

const urlPrefix = 'order-portal/';

export const getConfig = (
  configuration: AxiosRequestConfig,
  url: string,
  method: Method,
  requestBody: any,
  cancelToken?: CancelToken,
): AxiosRequestConfig => {
  const config: AxiosRequestConfig = {
    ...configuration,
    method,
    url,
    cancelToken,
    // Make sure objects are serialized into arrays so spatie/laravel-query-builder works
    paramsSerializer: (p) => {
      return qs.stringify(p, { arrayFormat: 'brackets' });
    },
  };

  if (method === AVAILABLE_HTTP_METHODS.GET) {
    return { ...config, params: requestBody };
  }

  return { ...config, data: requestBody };
};

export default function gatewayRequest<T>(
  url: string,
  method: Method = AVAILABLE_HTTP_METHODS.GET,
  body: any = {},
  configuration: AxiosRequestConfig = {},
): AxiosPromise<T> {
  const config = getConfig(configuration, urlPrefix.concat(url), method, body);
  return axios(config);
}
