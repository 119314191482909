import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import 'react-toastify/dist/ReactToastify.min.css';
import Nav from '../Nav/Nav';

const Page: React.FunctionComponent = ({ children }) => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet
        titleTemplate={`%s - ${t('shared:title')}`}
        defaultTitle={t('shared:title')}
      >
        <meta name="description" content="Order your products" />
      </Helmet>
      <Nav />
      <Container fluid="xl">
        <main>
          {children}
        </main>
      </Container>
    </>
  );
};

export default Page;
