import React from 'react';
import Page from './Page';

// Because this can be shown from where the translation context
// isn't present, we just use english only.
const ErrorPage = () => (
  <Page>
    <div>An error occurred!</div>
  </Page>
);

export default ErrorPage;
