import React from 'react';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger, { OverlayTriggerRenderProps } from 'react-bootstrap/OverlayTrigger';
import { v4 } from 'uuid';

interface HoverTooltipProps {
  text: string;
  id?: string;
  children: React.ReactElement | ((props: OverlayTriggerRenderProps) => React.ReactNode);
}

const HoverTooltip = ({ text, id, children }: HoverTooltipProps) => (
  <OverlayTrigger
    placement="auto"
    rootClose
    overlay={
      <Tooltip id={id ?? v4()}>{text}</Tooltip>
    }
  >
    {children}
  </OverlayTrigger>
);

export default HoverTooltip;
