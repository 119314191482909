import gatewayRequest, { AVAILABLE_HTTP_METHODS } from '../../Helpers/api/gatewayRequest';
import { PaginatedResultSet, ResultSet } from '../../Helpers/api/api';
import { CombinedNotificationPreferences, Location } from '../Interfaces/types';
import { LocationsRequest, UpdateNotificationPreferencesRequest } from '../Interfaces/requests';

export default class LocationServices {
  static async index(
    tenant_id: number,
    params?: LocationsRequest,
  ) {
    return gatewayRequest<PaginatedResultSet<Location[]>>(
      `${tenant_id}/locations`,
      AVAILABLE_HTTP_METHODS.GET,
      params,
    );
  }

  static async show(
    tenant_id: number,
    location_uuid: string,
  ) {
    return gatewayRequest<ResultSet<Location>>(
      `${tenant_id}/locations/${location_uuid}`,
      AVAILABLE_HTTP_METHODS.GET,
    );
  }

  static async showContact(
    tenant_id: number,
    location_uuid: string,
  ) {
    return gatewayRequest<ResultSet<CombinedNotificationPreferences>>(
      `${tenant_id}/locations/${location_uuid}/contact`,
      AVAILABLE_HTTP_METHODS.GET,
    );
  }

  static async updateContact(
    tenant_id: number,
    location_uuid: string,
    body: UpdateNotificationPreferencesRequest,
  ) {
    return gatewayRequest<ResultSet<void>>(
      `${tenant_id}/locations/${location_uuid}/contact`,
      AVAILABLE_HTTP_METHODS.PATCH,
      body,
    );
  }
}
