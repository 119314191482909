import React, { ReactNode, ReactNodeArray } from 'react';
import { useAsync } from 'react-async';
import bootstrapAppData from '../Data/Bootstrap';
import * as authClient from '../Data/AuthClient';
import FullPageSpinner from '../Components/Core/FullPageSpinner';
import { User } from '../Data/Users';
import { UpdateUserRequest, UpdateUserPasswordRequest } from '../Settings/Interfaces/SettingsRequests';

interface AuthContext {
    user: User|null;
    signIn: (username: string, password: string, remember: boolean) => Promise<void>;
    signOut: () => Promise<void>;
    updateUser: (updates: UpdateUserRequest) => Promise<void>;
    changePassword: (updates: UpdateUserPasswordRequest) => Promise<void>;
    buildTenantSlug: () => string;
}

const AuthContext = React.createContext<AuthContext>({
  user: null,
  signIn: () => Promise.resolve(),
  signOut: () => Promise.resolve(),
  updateUser: () => Promise.resolve(),
  changePassword: () => Promise.resolve(),
  buildTenantSlug: () => ':tenantSlug()',
});

interface AuthProviderProps {
    children: ReactNode|ReactNodeArray;
}

function AuthProvider(props: AuthProviderProps) {
  const [firstAttemptFinished, setFirstAttemptFinished] = React.useState(false);
  const {
    data = { user: null },
    error,
    isRejected,
    isPending,
    isSettled,
    reload,
  } = useAsync({
    promiseFn: bootstrapAppData,
  });

  React.useLayoutEffect(() => {
    if (isSettled) {
      setFirstAttemptFinished(true);
    }
  }, [isSettled]);

  if (!firstAttemptFinished) {
    if (isPending) {
      return <FullPageSpinner />;
    }
    if (isRejected) {
      return (
        <div>
          <p>Uh oh... There&apos;s a problem. Try refreshing the app.</p>
          {error && <pre>{error.message}</pre>}
        </div>
      );
    }
  }

  const tenant_users = data?.user
    ?.tenant_users
    .filter((tenant_user) => tenant_user.permissions.indexOf('cop_access') >= 0) ?? [];

  const buildTenantSlug = (): string => {
    if (data?.user) {
      return `:tenantSlug(${tenant_users.map((option) => option.tenant.url_slug).join('|')})`;
    }
    return ':tenantSlug()';
  };

  const signIn = (
    email: string, password: string, remember: boolean,
  ) => authClient.signIn(
    email, password, remember,
  ).then(reload);

  const signOut = () => authClient
    .signOut()
    .then(() => window.location.replace('/'))
    .then(reload);

  const updateUser = (updates: UpdateUserRequest): any => authClient
    .updateUser(updates);

  const changePassword = (updates: UpdateUserPasswordRequest): any => authClient
    .changePassword(updates);

  return (
    <AuthContext.Provider
      value={{
        user: data.user
          ? {
            ...data.user,
            tenant_users,
          } : null,
        signIn,
        signOut,
        updateUser,
        buildTenantSlug,
        changePassword,
      }}
      {...props}
    />
  );
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
}

export { AuthProvider, useAuth };
