import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import Page from './Page';

// Because this can be shown from where the translation context
// isn't present, we just use english only.
const MaintenancePage = () => {
  const { t } = useTranslation('shared');
  return (
    <Page>
      <div className="d-flex h-100 text-center justify-content-center">
        <div className="align-self-center">
          <div className="row">
            <div className="col-sm-12">
              <FontAwesomeIcon className="display-1 m-4" icon={faExclamationTriangle} />
              <h3 className="mt-3">
                <strong>{t('maintenance_page.title')}</strong>
              </h3>
              <p>
                {t('maintenance_page.message')}
              </p>
              <a href="/">
                <button type="button" className="btn btn-outline-success rounded">
                  {t('maintenance_page.return_home')}
                </button>
              </a>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-sm-12">
              <h5>{t('maintenance_page.support_header')}</h5>
              <form action="mailto:servicedesk@routeique.com">
                <button type="submit" className="btn btn-outline-success rounded">
                  <strong>{t('maintenance_page.contact_support')}</strong>
                </button>
              </form>
            </div>
          </div>
          <footer className="footer fixed-bottom">
            <img className="mb-3" width="350" height="110" alt="logo" src="/images/routeique-logo.png" />
          </footer>
        </div>
      </div>
    </Page>
  );
};

export default MaintenancePage;
