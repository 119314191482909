import React, {
  ReactNode,
  ReactNodeArray,
  useCallback, useEffect,
  useState,
} from 'react';
import Axios from 'axios';
import { useInterval, useIsMount } from '../hooks';

interface MaintenanceModeContext {
  inMaintenanceMode: boolean;
  down: () => void;
  up: () => void;
}

const MaintenanceModeContext = React.createContext<MaintenanceModeContext>({
  inMaintenanceMode: false,
  down: () => undefined,
  up: () => undefined,
});

interface MaintenanceModeProviderProps {
    children: ReactNode|ReactNodeArray;
}

function MaintenanceModeProvider(props: MaintenanceModeProviderProps) {
  const [inMaintenanceMode, setInMaintenanceMode] = useState<boolean>(false);
  const isMount = useIsMount();
  const up = useCallback(() => setInMaintenanceMode(() => false), [setInMaintenanceMode]);
  const down = useCallback(() => setInMaintenanceMode(() => true), [setInMaintenanceMode]);

  useEffect(() => {
    if (isMount) {
      // We only want to mount the interceptors at once.
      Axios.interceptors.response.use(undefined, async (error) => {
        if (error.response && (error.response.status === 503)) {
          down();
        } else {
          up();
        }
        return Promise.reject(error);
      });
    }
  }, [isMount, up, down]);

  const checkIfOut = useCallback(() => {
    if (inMaintenanceMode) {
      // TODO Replace with a proper status or health check end point on API.
      Axios.get('/api/auth/user');
    }
  }, [inMaintenanceMode]);

  useInterval(checkIfOut, 30000);

  return <MaintenanceModeContext.Provider value={{ inMaintenanceMode, up, down }} {...props} />;
}

function useMaintenanceMode() {
  const context = React.useContext(MaintenanceModeContext);
  if (context === undefined) {
    throw new Error('useMaintenanceMode must be used within a MaintenanceModeProvider');
  }
  return context;
}

export { MaintenanceModeProvider, useMaintenanceMode };
