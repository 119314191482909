import Axios from 'axios';
import {
  getUser,
} from './AuthClient';

/**
 * We initialize our Axios interceptors and other settings.
 */
const initializeAxios = async () => {
  Axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
  Axios.defaults.withCredentials = true;
  await Axios.get('/sanctum/csrf-cookie');
};

async function bootstrapAppData() {
  await initializeAxios();
  const user = await getUser();
  return {
    user,
  };
}

export default bootstrapAppData;
