import React, {
  ReactNode,
  ReactNodeArray, useCallback, useEffect, useState,
} from 'react';
import {
  useParams,
} from 'react-router-dom';
import _ from 'lodash';
import { useAuth } from '../Context/AuthContext';
import FullPageSpinner from '../Components/Core/FullPageSpinner';
import { TenantUser } from '../Data/Users';
import { SettingKey } from '../Settings/Interfaces/types';
import SettingsService from '../Settings/Services/SettingsService';
import { Tenant } from './Interfaces/types';

interface TenantContextValue {
  tenant: Tenant;
  tenant_user: TenantUser;
  getSetting: <SettingValue>(key: SettingKey) => Promise<SettingValue>;
}

const TenantContext = React.createContext<TenantContextValue>({} as TenantContextValue);

interface TenantProviderProps {
  children: ReactNode|ReactNodeArray;
  prePopulateSettingCache: SettingKey[];
}

interface TenantRouteProps {
  tenantSlug: string;
}

type SettingCache = {
  [key in SettingKey]: any;
}

const initialSettingCache = {} as SettingCache;

function TenantProvider({ prePopulateSettingCache, ...props }: TenantProviderProps) {
  const { user } = useAuth();
  const { tenantSlug } = useParams<TenantRouteProps>();
  const [tenantUser, setTenantUser] = useState<null|TenantUser>(null);
  const [settingCache, setSettingCache] = useState<SettingCache>(initialSettingCache);

  useEffect(() => {
    const found = _.find(user?.tenant_users, (subject) => subject.tenant.url_slug === tenantSlug);
    setTenantUser(found ?? null);
  }, [user, tenantSlug]);

  useEffect(() => {
    if (tenantUser) {
      // Clear out the previous cache and fill the cache again.
      setSettingCache(() => initialSettingCache);
      prePopulateSettingCache.forEach((key) => {
        SettingsService.getSetting(tenantUser.tenant.id, key)
          .then(({ data: { data: { value } } }) => setSettingCache((prev) => ({
            ...prev,
            [key]: value,
          })));
      });
    }
  }, [tenantUser, prePopulateSettingCache, setSettingCache]);

  const getSetting = useCallback(async <SettingValue extends unknown>(key: SettingKey) => {
    if (!tenantUser) {
      return Promise.reject();
    }
    if (key in settingCache) {
      return settingCache[key];
    }
    const response = await SettingsService.getSetting<SettingValue>(tenantUser.tenant.id, key);
    setSettingCache((prev) => ({ ...prev, [key]: response.data.data.value }));
    return response.data.data.value;
  }, [tenantUser, settingCache, setSettingCache]);

  if (tenantUser === null) {
    return <FullPageSpinner />;
  }

  return (
    <TenantContext.Provider
      value={{
        tenant: tenantUser.tenant,
        tenant_user: tenantUser,
        getSetting,
      }}
      {...props}
    />
  );
}

function useTenant() {
  const context = React.useContext(TenantContext);
  if (context === undefined) {
    throw new Error('useTenant must be used within a TenantProvider');
  }
  return context;
}

export { TenantProvider, useTenant };
