import * as React from 'react';
import Spinner from 'react-bootstrap/Spinner';

const FullPageSpinner = () => (
  <Spinner animation="border" role="status">
    <span className="sr-only">Loading...</span>
  </Spinner>
);

export default FullPageSpinner;
